.anaHeadingA {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  /* identical to box height */

  text-transform: capitalize;

  color: #000000;
  margin-bottom: 0px !important;
}

.anaHeadingB {
  margin-top: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-transform: capitalize;

  color: #757575;
}

.pbiDashboardItem {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin-top: 20px;
}

.report-sample iframe {
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.report-style-class iframe {
  border: none;
}

.pbiSelectOption {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12.3121px;
  line-height: 14px;
  text-transform: capitalize;

  color: #000000;
  background-color: transparent;
  border: none;
  padding: 10px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 20px;
}
