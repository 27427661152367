.sw-theme-arrows {
  border-radius: 5px;
  border: 1px solid #ddd;
  > .sw-container {
    min-height: 200px;
  }
  .step-content {
    padding: 0 10px;
    border: 0 solid #d4d4d4;
    background-color: #fff;
    text-align: left;
  }
  .sw-toolbar {
    padding: 10px;
    margin-bottom: 0 !important;
  }
  > ul.step-anchor {
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 0;
    background: #f5f5f5;
    border-radius: 0;
    border-top-right-radius: 5px;
    list-style: none;
    overflow: hidden;
    li + li:before {
      padding: 0;
    }
    > li {
      > a {
        color: #bbb;
        text-decoration: none;
        padding: 10px 0 10px 45px;
        position: relative;
        display: block;
        border: 0 !important;
        border-radius: 0;
        outline-style: none;
        background: #f5f5f5;
        &:hover {
          color: #bbb;
          text-decoration: none;
          padding: 10px 0 10px 45px;
          position: relative;
          display: block;
          border: 0 !important;
          border-radius: 0;
          outline-style: none;
          background: #f5f5f5;
        }
        &:after {
          content: " ";
          display: block;
          width: 0;
          height: 0;
          border-top: 50px solid transparent;
          border-bottom: 50px solid transparent;
          border-left: 30px solid #f5f5f5;
          position: absolute;
          top: 50%;
          margin-top: -50px;
          left: 100%;
          z-index: 2;
        }
        &:before {
          content: " ";
          display: block;
          width: 0;
          height: 0;
          border-top: 50px solid transparent;
          border-bottom: 50px solid transparent;
          border-left: 30px solid #ddd;
          position: absolute;
          top: 50%;
          margin-top: -50px;
          margin-left: 1px;
          left: 100%;
          z-index: 1;
        }
      }
      &:first-child > a {
        padding-left: 15px;
      }
      > a:hover {
        color: #bbb;
        text-decoration: none;
        outline-style: none;
        background: #f5f5f5;
        border-color: #f5f5f5;
        &:after {
          border-left-color: #f5f5f5;
        }
      }
      &.clickable > a:hover {
        color: $primary-color !important;
        background: $success-color !important;
      }
      &.active > a {
        border-color: $success-color !important;
        color: #fff !important;
        background: $success-color !important;
        &:after {
          border-left: 30px solid $success-color !important;
        }
      }
      &.done > a {
        border-color: #b1dfbb !important;
        color: #fff !important;
        background: #b1dfbb !important;
        &:after {
          border-left: 30px solid #b1dfbb;
        }
      }
      &.danger > a {
        border-color: $danger-color !important;
        color: #fff !important;
        background: $danger-color !important;
        &:after {
          border-left: 30px solid $danger-color !important;
        }
      }
      &.disabled > a {
        color: #eee !important;
        &:hover {
          color: #eee !important;
        }
      }
    }
  }
  &::before {
    border: 10px solid #f3f3f3;
    border-top: 10px solid $success-color;
  }
}

@media screen and (max-width: 768px) {
  .sw-theme-arrows > {
    ul.step-anchor {
      border: 0;
      background: #ddd !important;
    }
    .nav-tabs > li {
      float: none !important;
      margin-bottom: 0;
    }
    ul.step-anchor > li > a {
      padding-left: 15px;
      margin-right: 0;
      margin-bottom: 1px;
      &:hover {
        padding-left: 15px;
        margin-right: 0;
        margin-bottom: 1px;
      }
      &:after, &:before {
        display: none;
      }
    }
  }
}