@import 'assets/scss/style.scss';

/*.pcoded-navbar.mob-open ~ .pcoded-header:before,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before{
  display: none;
}

.nav-outside.mob-backdrop ~ .pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0,0,0,0.25);
}
.pcoded-header .dropdown .notification .noti-body li {
  width: 100%;
}

.header-chat .h-list-footer .input-group .btn-attach {
  padding: 0;
  width: 35px;
  height: 35px;
}

.header-chat .h-list-footer .input-group .btn-send {
  top: 7px;
}*/

.pcoded-navbar {
  &.mob-open ~ .pcoded-header:before,
  &.navbar-collapsed:hover ~ .pcoded-header:before {
    display: none;
  }
}

.nav-outside.mob-backdrop ~ .pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-header .dropdown .notification .noti-body li {
  width: 100%;
}

.header-chat .h-list-footer .input-group {
  .btn-attach {
    padding: 0;
    width: 35px;
    height: 35px;
  }

  .btn-send {
    top: 7px;
  }
}

.custom-selector > div {
  height: 50px !important;
  border-color: #d3d3d3 !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 5px !important;
  padding-top: 5px !important;
}

.ant-select-borderless > .ant-select-selector {
  border: 0px solid white !important;
}

.ant-message {
  z-index: 34232342342 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #f8a94f !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #f8a94f;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #f8a94f;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #f8a94f !important;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #f8a94f;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f8a94f !important;
}

.ant-tabs-tab {
  color: #363636 !important;
}

.camera-modal {
  max-width: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 100vh !important;
  display: flex !important;
  position: fixed !important;
  z-index: 100000 !important;
}

table#albums {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
}

////////////////////////////////////

.sheet th {
  background-color: #626262 !important;
  color: #fff !important;
  border: none !important;
}

.table-curved {
  border-collapse: collapse !important;
}
.table-curved th {
  padding-left: 10px !important;
  background-color: #626262 !important;
  color: #fff !important;
  padding-right: 10px !important;
  border: none !important;
}

.table-curved tr {
  padding-bottom: 20px !important;
}

.table-curved td {
  background-color: rgb(248, 248, 248) !important;

  // border-bottom: 8px solid red !important;
  // border-top: 8px solid red !important;
}
.table-curved td:first-child {
  border-radius: 15px 0 0 15px !important;
}
.table-curved td:last-child {
  border-radius: 0 15px 15px 0 !important;
}

.table-curved th:first-child {
  border-radius: 12px 0 0 12px !important;
}
.table-curved th:last-child {
  border-radius: 0 12px 12px 0 !important;
}

.ant-table table {
  border-spacing: 0 10px !important;
}

.shifts table {
  border-spacing: 0 0 !important;
}

.ant-table .ant-table-expanded-row-fixed {
  background-color: #fff !important;
}

.borderless td,
.borderless th {
  border: none !important;
}

.tmb {
  margin-bottom: 200px !important;
}

.fullWidth {
  width: 100% !important;
}

.timeEntryLeave {
  width: calc(100% - 6px) !important;
}

.react-time-picker__wrapper {
  height: 38px !important;
  width: 100% !important;
  border: thin solid #d3d3d3 !important;
  padding: 4px !important;
}

.calender-end {
  width: 100% !important;
}

.chatbox-container {
  position: fixed;
  bottom: 0px;
  height: 600px;
  left: 50%;
  width: 400px;
  z-index: 2342342423 !important;
}

.modal-loc {
  height: 100vh !important;
  width: 100vw !important;
}

// .table-curved td:first-child:before {
//   content: "";
//   display: block;
//   width: 8px;
//   height: 100%;
//   left: 0;
//   top: 0;
//   background-color: #F8A64B !important;
//   border-radius: 12px 0 0 12px !important;
//   position: absolute !important;
// }

///////////////////////////////////

.helper {
  --reactour-accent: #5cb7b7 !important;
  line-height: 1.3;
  color: #2d2323;
  max-width: 375px !important;
}

.ant-spin-dot-item {
  background-color: #f8a94f !important;
}

.ant-badge-status-text {
  color: #fff !important;
}

.c-pointer {
  cursor: pointer !important;
}

svg {
  vertical-align: baseline;
}
@media only screen and (max-width: 991px) {
  /*

  .nav-outside ~ .pcoded-header,
  .nav-outside ~ .pcoded-main-container {
    margin-left: 0;
  }

  .nav-outside.mob-fixed ~ .pcoded-main-container {
    padding-top: 56px;
  }

  .pcoded-header .container>.collapse:not(.show) .mr-auto, .pcoded-header>.collapse:not(.show) .mr-auto {
    display: inline-flex;
    justify-content: center;
  }

  */

  .nav-outside {
    ~ {
      .pcoded-header,
      .pcoded-main-container {
        margin-left: 0;
      }
    }

    &.mob-fixed ~ .pcoded-main-container {
      padding-top: 56px;
    }

    .pcoded-header {
      .container > .collapse:not(.show) .mr-auto,
      > .collapse:not(.show) .mr-auto {
        display: inline-flex;
        justify-content: center;
      }
    }
  }
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.zoom-container {
  position: relative;
  display: flex;
}

.img-preview-section-container {
  display: none;
}
.zoom-container:hover .img-preview-section-container {
  display: block;
  position: relative;
  left: 50px;
}
.zoom-container:hover .img-preview-section-container {
  left: 100%;
  position: absolute;
  background: #fff;
  // border: 1px solid $border-color;
}
.carousel-inner {
  overflow: visible;
  z-index: 1050;
}
.form-control[type='file'] {
  height: auto;
  padding-left: 0.45rem;
}
.table {
  .badge {
    margin-right: 0.25rem;
  }
}
.mb-3.row {
  > .col,
  .col.d-flex {
    @media (max-width: 768px) {
      margin-top: 5px;
      margin-bottom: 5px;
      &:nth-child(2) {
        flex-direction: column;
        text-align: left;
        > span {
          justify-content: flex-start !important;
          .form-control {
            max-width: 150px;
          }
        }
        .btn {
          margin-top: 8px;
          max-width: fit-content;
          margin-left: 0 !important;
        }
      }
    }
  }
}

.row.justify-content-between > .col > .d-flex.align-items-center strong {
  margin: 0 5px;
}

@media (max-width: 575px) {
  .row.justify-content-between > *[class*='col-'],
  .row.justify-content-between > .col {
    flex: 100%;
    .pagination {
      justify-content: flex-start !important;
    }
    &:first-child {
      margin-bottom: 8px;
    }
    > .d-flex.align-items-center {
      input {
        width: 40px !important;
      }
    }
  }
}
td {
  img.wid-40 {
    max-width: 40px;
  }
}
.new-cust-card {
  @media (max-width: 1024px) {
    .d-inline-block {
      width: calc(100% - 55px);
    }
    .align-middle .status {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}
.table th,
.table td {
  vertical-align: middle;
}
.cover-img-block {
  overflow: hidden;
  z-index: 1;
}
.user-about-block {
  position: relative;
  z-index: 5;
}
@media only screen and (max-width: 1200px) {
  .user-card .user-about-block {
    margin-top: -10px;
  }
}

.alert {
  .float-right {
    margin-left: 8px;
    float: none !important;
  }
}
@media (max-width: 768px) {
  .modal-open .modal {
    padding-left: 0 !important;
  }
}
.carousel.slide {
  overflow: hidden;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  left: 18px !important;
}
.smooth-dnd-container.horizontal {
  button {
    border: none;
  }
}
.ui-pnotify-icon {
  > span {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
  }
}

.table.table-bordered.table-hover {
  th {
    select.form-control {
      min-width: 140px;
    }
  }
}
.filter-bar .navbar {
  @media (max-width: 690px) {
    padding: 0.5rem 1rem 1rem;
    #dropdown-basic {
      padding-left: 10px;
    }
    .f-view {
      padding-left: 15px;
      margin-top: 8px;
    }
  }
}

// for pricing design starts here

section.pricing {
  background: transparent;
  background: linear-gradient(to right, transparent, transparent);
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}

.notification-top-bar {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  height: 40px !important;
  line-height: 40px !important;
  width: 100% !important;
  background: #1abc9c !important;
  text-align: center !important;
  color: #ffffff !important;
  font-family: sans-serif !important;
  font-weight: lighter !important;
  font-size: 14px !important;
  z-index: 234234234234234234 !important;
}
.notification-top-bar p {
  padding: 0 !important;
  margin: 0 !important;
}
.notification-top-bar p a {
  padding: 5px 10px !important;
  border-radius: 3px !important;
  background: #fff !important;
  color: #1abc9c !important;
  font-weight: bold !important;
  text-decoration: none !important;
}
