.gSettingsRow {
  justify-content: center;
}

.prefRow {
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  align-items: center;
  display: flex;
  height: 59px;
  padding-left: 15px;
  padding-right: 32px;
  margin-top: 25px;
}

.prefRowLabel {
  margin-bottom: 0 !important;
}

.prefSwitchCls {
  // width: 49px;
  // height: 29px;
}

.prefSwitchCont .ant-switch-checked {
  background-color: #f89722;
}

.prefSecondaryText {
  margin-top: 10px;
}

.comSaveBtn {
  width: 207px;
  height: 51px;
  background: #f89722;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.comSaveCont {
  display: flex;
  justify-content: flex-end;
}

.userAvatarPro {
  width: 148px;
  height: 148px;
  border-radius: 74px;
}

.avaContPro {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featEmpty {
  width: 148px;
  height: 148px;
  border-radius: 74px;
  background-color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.emptyAvaIcon {
  font-size: 24px;
}

.uploadHover {
  position: absolute;
  top: 0;
  width: 148px;
  height: 148px;

  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 74px;
  display: flex;
}

.uploadHover:hover {
  display: flex;

  background-color: rgba(0, 0, 0, 0.5);

  .avaIconUp {
    display: inline;
  }
}

.avaIconUp {
  display: none;
  font-size: 24px;
  color: #ffff;
}

.proPhoneInput {
  width: calc(100% - 41px);
  position: relative;
  font-size: 14;
  margin-top: 0 !important;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 10px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  line-height: 25px;
  height: 40px;
  outline: none;
  margin-left: 40px;
}
.comPhoneInput {
  width: calc(100% - 41px);
  position: relative;
  font-size: 14;
  margin-top: 0 !important;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 10px;
  margin-left: 0;
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  line-height: 25px;
  height: 50px;
  outline: none;
  margin-left: 40px;
}

.fullDaySwitch .ant-form-item-control {
  flex-grow: unset !important;
}

.fullDaySwitch .ant-form-item-label {
  padding-bottom: 0px !important;
}

.fullDaySwitch .ant-switch-checked {
  background-color: #f89722;
}

.sigCanvas {
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  max-width: 100%;
}

.uploadAtt .avatar-uploader > .ant-upload {
  width: 100%;
}

.customSwapUserCard {
  border: 1px solid #f89722;
  box-sizing: border-box;
  border-radius: 20px;
  max-width: 523px;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
  .cardPopover {
    position: absolute;
    top: 4px;
    right: 0;
  }
  div {
    .swapjobtitle {
      padding-left: 1em;
      display: flex;
      flex-direction: row;
      p {
        display: flex;
        width: 40px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 100% */

        text-transform: capitalize;

        color: #757575;
      }
    }
    .swapjobvalue {
      p {
        margin-left: 1em;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        width: auto;
        text-transform: capitalize;

        color: #333333;
      }
    }
  }
}

.superUserCard {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 20px;
  justify-content: space-between;
  
  padding-left: 2rem;
  padding-right: 50px;

  padding-bottom: 5px;
  position: relative;

  
  .leavedes p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    color: #757575;
  }
  div {
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      text-align: center;
      text-transform: capitalize;

      color: #000000;
    }
  }
}

.superPopover {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 10;
}

.superswapselect .ant-select-selector {
  height: 40px;
  border-radius: 6px !important;
}

.swapMenuBtn:focus {
  outline: none !important;
  box-shadow: none;
}

.leaveDesTrun {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.swapApplyBtn:focus {
  outline: none !important;
  box-shadow: none;
}


.hoverSuper :hover{
  border-color: #f89722 !important;
}