.userDetailCont {
  padding-top: 150px;
  padding-left: 60px;
  padding-bottom: 150px;
  padding-right: 20px;
}

.avatarCont {
  background-color: rgba(255, 229, 198, 0.34);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.userDetailsModal .ant-modal-body {
  padding: 0px !important;
  border-radius: 20px !important;
}

.userDetailsModal .ant-modal-content {
  border-radius: 20px !important;
  overflow: hidden;
}

.userDetailsModal {
  width: 850px !important;
  border-radius: 20px !important;
}

.uAvatar {
  width: 140px !important;
  height: 140px !important;
  justify-content: center !important;
  align-items: center !important ;
  display: flex !important;
}

.uDetailHead {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 10px;
  text-transform: capitalize;
  margin-bottom: 10px !important;
}

.UDetailLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;

  color: #757575;
}

.UDetailsItem {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;

  color: #f89722;
}

.uLabelCont {
  width: 150px;
}

.labelMrg {
  margin-left: 15px;
}

.UDetailsItem {
  margin-right: 15px;
}

.uDetailsLine {
  border: 1px solid #f89722;
  width: 115px;
  height: 0px;
}

.uCompLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  text-transform: capitalize;
  padding-left: 10px;
  padding-right: 15px;
}

.uDetailsMargin {
  margin-top: 50px;
}

.uDetailTab {
  cursor: pointer;
}

.detailModalName {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height */

  text-transform: capitalize;

  color: #f89722;
  margin-top: 25px;
  width: 100%;
  text-align: center;
}
.avaInner {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
