.tModalCont {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  //   background-color: rgba(196, 196, 196, 0.76);
  //   z-index: 50000;
}

.modalCont {
  background-color: #ffff;
  //   background-color: red;
  border-radius: 20px;
}

.modalHeader {
  background-color: #f89722;
  padding-top: 25px;
  height: 80px;
}

.tourBodyC {
  color: #f89722;
}

.headerHeading {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 0 !important;
  text-transform: capitalize;

  color: #ffffff;
}

.welcomeModalCon .ant-modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.confirmModalCon .ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
}
.welcomeModalCon .ant-modal-close {
  color: #ffff;
}

.helloUser {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;

  text-transform: capitalize;
  margin-bottom: 0 !important;
  color: #101849;
  margin-top: 100px;
}

.welcomeTxt {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  /* identical to box height */

  text-transform: capitalize;
  margin-bottom: 0 !important;

  color: #101849;
}

.bodyCont {
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 60px;
  padding-bottom: 70px;
  justify-content: space-between;
}

.welDesc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  width: 284px;
  color: rgba(3, 5, 15, 0.5);
  margin-top: 18px;
}

.getStartedCTA {
  width: 250px;
  height: 48px;
  background: #f89722;
  border-radius: 10px;
  border: 0px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  color: #ffffff;
}

.laterCTA {
  width: 250px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #f89722;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.ctaMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.tryLaterCta {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-transform: capitalize;

  color: #f89722;
  margin-top: 22px;
  background-color: transparent;
  border: 0px;
}

.welCircleContA {
  position: absolute;
  left: 30px;
}

.welCirA {
  position: absolute;
}
.welCirB {
  position: absolute;
  left: 26px;
  top: 22px;
}

.welCirC {
  position: absolute;
  right: -100px;
  top: 20px;
}

.welCircleContB {
  position: absolute;
  right: 300px;
  top: 320px;
}

.tipHeading {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  text-transform: capitalize;

  color: #101849;
}

.tipDesc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: rgba(3, 5, 15, 0.5);
}

.tipCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 375px;
}

.tipIconCont {
  margin-top: 20px;
}

.orgPickerDashboard .ant-select-selector {
  height: 40px !important;
  justify-content: center;
  align-items: center;
}

.orgPickerShift .ant-select-selector {
  border-radius: 0px !important;
  height: 40px !important;
  justify-content: center;
  align-items: center;
}

.orgPickerPQ .ant-select-selector {
  border-radius: 0px !important;
  height: 34px !important;
  justify-content: center;
  align-items: center;
}

.orgPickerPQ {
  margin-left: 20px;
  margin-top: 10px;
}

.modalPadding {
  position: absolute;
  top: 90px;
  left: 10%;
  right: 10%;
}

.checkboxListCont {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: -100px;
  right: -200px;
}

.wlImg {
  width: 320px;
  height: 270px;
}

.checkCont {
  max-width: 300px;
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 2147483647522;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.checkHeading {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  text-transform: capitalize;
  color: #101849;
}

.checkSubHeading {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(3, 5, 15, 0.4);
  margin-top: 12px;
}

.tourCheckBox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f89722;
  border-color: #e0e0e0;
}

.report-style-class {
  height: 800px;
}
.report-sample {
  height: 500px;
}
